<template>
  <div class="flex flex-col items-center mt-32">
    <h1
      class="text-4xl md:text-6xl font-extrabold font-display mb-7 text-center"
    >
      404<br />Pagina non trovata
    </h1>

    <a
      @click="
        this.$router.push({
          name: 'Home',
          params: { locale: this.$i18n.locale },
        })
      "
      class="w-fit cursor-pointer flex justify-center rounded-full items-center bg-yellow-200 hover:bg-yellow-400 transition duration-150 overflow-hidden p-4 pl-6 pr-8 gap-3"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 text-neutral-900"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      </svg>
      <h3 class="text-xl md:text-xl uppercase font-semibold text-neutral-900">
        Home
      </h3>
    </a>
  </div>
</template>

<script>
export default {
  name: "PageNotFoundView",
};
</script>
