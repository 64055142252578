<template>
  <section id="footer">
    <div class="flex flex-col items-center py-4">
      <router-link
        @click="goToStart"
        :to="{ name: 'Home', params: { locale: this.$i18n.locale } }"
      >
        <div class="my-4">
          <img :src="require('@/assets/Logo White.svg')" class="h-16" alt="" />
        </div>
      </router-link>
      <div class="text-center">
        <p class="font-bold">All4Newz © 2022 - {{ $t("footer.rights") }}</p>
        <p class="my-2">
          All4Newz News SA <br />Rue de Cornavin 6 - 1201 Genève, Switzerland -
          {{ $t("footer.VAT") }} 310.760.556
          <span v-if="this.$i18n.locale === 'de'">
            <br />
            Telefonnummer: +41 79 344 38 2
            <br />
            Email:
            <a href="mailto:amministrazione@smsocialmanagement.com"
              >info@all4newz.com</a
            >
            <br />
            Gesetzlicher Vertreter: Dannis Roger
          </span>
        </p>
        <p class="">
          <router-link
            @click="goToStart"
            :to="{ name: 'Privacy', params: { locale: this.$i18n.locale } }"
          >
            {{ $t("footer.cookie-policy") }} - {{ $t("footer.privacy-policy") }}
          </router-link>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    goToStart() {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>
