<template>
  <div class="bg-neutral-900 w-screen h-screen z-50 fixed top-0 left-0"></div>
</template>

<script>
export default {
  name: "RedirectView",
  async mounted() {
    // let triggerPage = undefined;
    let triggerPages = [];
    let keyword = undefined;
    let page = 1;
    let shouldActivateTrigger = false;
    let utm_source = this.$route.query.utm_source;
    let adData = this.$route.query.adData;

    let newTaboola = this.$route.query.ntb == "true";

    let isWGM = this.$route.query.wgm == "true";

    const correctArid = (() => {
      const fixed = 20230826;

      const added = [...this.$route.query.slug].reduce((acc, cur) => {
        return acc + cur.charCodeAt(0);
      }, 0);

      return fixed + added + +utm_source;
    })();

    // let wgmFixedArid = this.$route.query.arid;
    let wgmFixedArid = correctArid;

    // let tbclid = this.$route.query.tbclid;

    if (Array.isArray(this.$route.query.adData)) {
      adData = this.$route.query.adData[this.$route.query.adData.length - 1];
    }

    let parsedAdData = {
      title: undefined,
      description: undefined,
      siteHost: undefined,
      ClickUrl: undefined,
    };
    let adDataModified = false;

    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page);
    }

    if (Array.isArray(utm_source)) {
      utm_source = utm_source.find((element) => {
        if (!Math.isNaN(parseInt(element))) {
          return true;
        }
      });
    }
    let classicMode = this.$route.query.cl == "true";

    if (!utm_source) {
      utm_source = this.$route.query.site_id;
    }
    try {
      let banners = await this.getBanners(utm_source, isWGM);
      let banner = banners[0];

      let altCondition = !isNaN(this.$route.query.altCond)
        ? +this.$route.query.altCond
        : undefined;
      const conditionIndex = altCondition ? altCondition : 0;

      let conditions = banner.attributes.conditions.data;
      // let condition = conditions.filter(function (el) {
      //   if (newTaboola) {
      //     return el.attributes.newTaboola_stato == true;
      //   } else {
      //     return el.attributes.a4n_stato == true;
      //   }
      // })[0];

      let condition = (() => {
        try {
          const tempCondition = conditions[conditionIndex];

          const isStatoActivated = (() => {
            if (newTaboola) {
              return tempCondition.attributes.newTaboola_stato == true;
            } else {
              return tempCondition.attributes.a4n_stato == true;
            }
          })();
          if (isStatoActivated) {
            return tempCondition;
          } else {
            return null;
          }
        } catch (error) {
          if (process.env.NODE_ENV == "development") {
            console.error(error);
          }
          return null;
        }
      })();

      // CLASSIC MODE
      // if (condition) {
      //   if (!classicMode) {
      //     if (!adData || adData == "null") {
      //       const url = new URL(
      //         // "http://127.0.0.1:5173/" +
      //         "https://www.hopondeals.com/" + this.$i18n.locale + "/r"
      //       );
      //       // console.log(url);
      //       url.searchParams.append("utm_source", utm_source);
      //       // console.log(window.location.href);
      //       url.searchParams.append("from", window.location.href);

      //       window.location = url;
      //     } else {
      //       parsedAdData = JSON.parse(adData);
      //       adDataModified = true;
      //     }
      //   }
      // }

      // triggerPage = condition.attributes.pagina;
      if (condition.attributes.a4n_pagine) {
        const arrayPages = condition.attributes.a4n_pagine.split(",");
        triggerPages = arrayPages.map((element) => {
          let n = element;
          if (classicMode) {
            n = parseInt(element);
            if (!Number.isNaN(n)) {
              n -= 1;
              n = Math.max(n, 0);
            }
          } else {
            n = n.trim();
          }
          return n;
        });
        // console.log(triggerPages);
      }
      // if (triggerPage) {
      //   triggerPage = 1;
      // }

      if (this.$route.query.keyword === undefined) {
        keyword = await this.getRandomKeyword(isWGM);
      } else {
        keyword = this.$route.query.keyword;
      }
      shouldActivateTrigger = true;
      // if (
      //   !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
      // ) {
      //   shouldActivateTrigger = false;
      // }

      // if (this.$route.query.utm_campaign == "mob") {
      //   shouldActivateTrigger = false;
      // }
    } catch (e) {
      shouldActivateTrigger = false;
    }

    let params = {
      shouldActivateTrigger: shouldActivateTrigger,
      slug: this.$route.query.slug,
      utm_source: utm_source, // triggerPage: triggerPage,
      utm_campaign: this.$route.query.utm_campaign, // triggerPage: triggerPage,
      altCond: this.$route.query.altCond, // triggerPage: triggerPage,
      triggerPages: triggerPages,
      keyword: keyword,
      classicMode: classicMode,
      isWGM: isWGM,
      isNewTaboola: newTaboola,
      wgmFixedArid: wgmFixedArid,
      adData_available: adDataModified,
      adData_title: parsedAdData.title,
      adData_description: parsedAdData.description,
      adData_ClickUrl: parsedAdData.ClickUrl,
      adData_siteHost: parsedAdData.siteHost,
    };

    // if (tbclid) {
    //   params.tbclid = tbclid;
    // }

    // console.log(params);
    if (shouldActivateTrigger && !classicMode && !adData) {
      //
    } else {
      this.$router.replace({
        name: "Post",
        params: params,
        query: { page },
      });
    }
  },

  methods: {
    async getBanners(utm_source, isWGM) {
      let locale = this.$i18n.locale;
      const backendURL = isWGM
        ? process.env.VUE_APP_BACKEND_URL_QF
        : process.env.VUE_APP_BACKEND_URL;
      const bearerToken = isWGM
        ? process.env.VUE_APP_BEARER_TOKEN_QF
        : process.env.VUE_APP_BEARER_TOKEN;

      const qs = require("qs");
      let parameters = {
        filters: {
          codice_paese: { $eq: locale },
          utm_source: { $eq: utm_source },
        },
        populate: [" conditions"],
      };
      let parametersString = qs.stringify(parameters);

      const url = new URL(backendURL + "/api/banners" + "?" + parametersString);
      // url.searchParams.append("populate", "*");

      let data = {};
      try {
        let res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        data = await res.json();

        // return data;
      } catch (error) {
        data = {};
      }

      // console.log(data.data[0].attributes.conditions.data[0]);

      return data.data;
    },

    async getRandomKeyword(isWGM) {
      let locale = this.$i18n.locale;
      const backendURL = isWGM
        ? process.env.VUE_APP_BACKEND_URL_QF
        : process.env.VUE_APP_BACKEND_URL;
      const bearerToken = isWGM
        ? process.env.VUE_APP_BEARER_TOKEN_QF
        : process.env.VUE_APP_BEARER_TOKEN;

      const qs = require("qs");
      let parameters = {
        filters: {
          codice_paese: { $eq: locale },
        },
        populate: ["conditions"],
      };
      let parametersString = qs.stringify(parameters);

      const url = new URL(
        backendURL + "/api/keywords" + "?" + parametersString
      );
      // url.searchParams.append("populate", "*");

      let data = {};
      try {
        let res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        data = await res.json();

        // return data;
      } catch (error) {
        data = {};
      }

      // console.log(data.data[0].attributes.conditions.data[0]);.

      let randomKeyWord =
        data.data[Math.floor(Math.random() * data.data.length)];
      let chosenKeyWord = randomKeyWord.attributes.keyword;

      return chosenKeyWord;
    },
  },
};
</script>
