<template>
  <div class="bg-neutral-900">
    <Navbar />

    <section id="content">
      <div class="container mx-auto px-2 md:px-12 min-h-screen">
        <router-view />
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: { Navbar, Footer },
  mounted() {
    // Activate Taboola
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "pageViewTest", id: 1590298 });
    window._tfa.push({ notify: "event", name: "page_view", id: 1590298 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1590298/tfa.js",
      "tb_tfa_script"
    );

    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "pageViewTest", id: 1590300 });
    window._tfa.push({ notify: "event", name: "page_view", id: 1590300 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1590300/tfa.js",
      "tb_tfa_script"
    );

    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "pageViewTest", id: 1590281 });
    window._tfa.push({ notify: "event", name: "page_view", id: 1590281 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1590281/tfa.js",
      "tb_tfa_script"
    );

    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "pageViewTest", id: 1590291 });
    window._tfa.push({ notify: "event", name: "page_view", id: 1590291 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1590291/tfa.js",
      "tb_tfa_script"
    );

    // qfindnow
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "pageViewTest", id: 1607705 });
    window._tfa.push({ notify: "event", name: "page_view", id: 1607705 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1607705/tfa.js",
      "tb_tfa_script"
    );

    // hopondeals
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "pageViewTest", id: 1607756 });
    window._tfa.push({ notify: "event", name: "page_view", id: 1607756 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1607756/tfa.js",
      "tb_tfa_script"
    );

    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1607746 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1607746/tfa.js",
      "tb_tfa_script"
    );

    // new
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1607708 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1607708/tfa.js",
      "tb_tfa_script"
    );

    // new double
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1607703 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1607703/tfa.js",
      "tb_tfa_script"
    );

    // hop native
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1607747 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1607747/tfa.js",
      "tb_tfa_script"
    );

    // hop mob camp
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1607758 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1607758/tfa.js",
      "tb_tfa_script"
    );

    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1704662 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1704662/tfa.js",
      "tb_tfa_script"
    );

    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1704663 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1704663/tfa.js",
      "tb_tfa_script"
    );
  },
};
</script>
