export default {
  "footer": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rechte vorbehalten"])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-Richtlinie"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Number"])}
  },
  "post": {
    "other-articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterlesen"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])}
  }
}