<template>
  <section id="nav">
    <div class="flex justify-center mb-2">
      <router-link
        :to="{ name: 'Home', params: { locale: this.$i18n.locale } }"
      >
        <div class="my-4">
          <img
            :src="require('@/assets/Logo Full Color.svg')"
            class="h-16 md:h-24"
            alt=""
          /></div
      ></router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavbarComponent",
};
</script>
