<template>
  <!-- {{ postData }} -->

  <!-- <router-link
        :to="{ name: 'Home', params: { locale: this.$i18n.locale } }"
      > -->

  <!-- <a
    :href="getLink" -->
  <router-link
    :to="{ name: 'Post', params: { locale: this.$i18n.locale, slug: getLink } }"
    class="rounded-3xl overflow-hidden relative w-full flex align-middle"
    :class="this.big ? ['h-[36rem]'] : ['h-48 md:h-96']"
  >
    <img :src="getImageLink" class="object-cover w-full" alt="" />
    <div
      class="absolute w-full h-full bg-gradient-to-t from-stone-800 mix-blend-multiply"
    ></div>
    <div class="absolute w-full h-full flex flex-col justify-end">
      <h3
        class="m-4 md:m-6 font-display"
        :class="
          this.big
            ? ['text-3xl md:text-6xl font-semibold']
            : ['text-xl md:text-3xl font-medium']
        "
      >
        {{ postData.attributes.titolo }}
      </h3>
    </div>
    <!-- </a> -->
  </router-link>
</template>

<script>
export default {
  name: "PostCardComponent",
  props: {
    postData: Object,
    big: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    getImageLink() {
      if (
        this.postData.attributes.cover &&
        this.postData.attributes.cover.data
      ) {
        return (
          process.env.VUE_APP_BACKEND_URL +
          this.postData.attributes.cover.data.attributes.url
        );
      }
      return require("@/assets/placeholder_post_image.svg");
    },
    getLink() {
      let link = this.postData.attributes.slug;
      return link;
    },
  },
};
</script>

<style></style>
