export default {
  "footer": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle rechten voorbehouden"])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookiebeleid"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid"])},
    "VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita IVA"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacten"])}
  },
  "post": {
    "other-articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees andere artikelen"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])}
  }
}