export default {
  "footer": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Number"])}
  },
  "post": {
    "other-articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  }
}