import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'
import Home from '../views/Home.vue'
import Post from '../views/Post.vue'
import Redirect from '../views/Redirect.vue'
import Privacy from '../views/Privacy.vue'
import PageNotFound from '../views/PageNotFound.vue'
// import AboutView from '../views/AboutView.vue'



const routes = [
  {
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale.toString()
      const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')

      if (!supportedLocales.includes(locale)) return next(process.env.VUE_APP_I18N_FALLBACK_LOCALE)

      if (i18n.global.locale.value !== locale) {
        i18n.global.locale.value = locale;
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'redirect',
        name: 'Redirect',
        component: Redirect
      },
      {
        path: 'post/:slug',
        name: 'Post',
        component: Post,
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy,
      },
      // {
      //   path: '/about',
      //   name: 'About',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      // }
    ]
  },
  {
    path: '/',
    redirect: i18n.global.locale.value
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  }

]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
