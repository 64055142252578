export default {
  "footer": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla rättigheter förbehållna"])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-policy"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integritetspolicy"])},
    "VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momsregistreringsnummer"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakter"])}
  },
  "post": {
    "other-articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läs andra artiklar"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])}
  }
}