export default {
  "footer": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i diritti riservati"])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy dei Cookie"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy della Privacy"])},
    "VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita IVA"])}
  },
  "post": {
    "other-articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi altri articoli"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])}
  }
}