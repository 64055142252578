<template>
  <div class="hero min-h-screen bg-base-100">
    <div class="container mx-auto px-2">
      <div class="h-8 sm:h-16"></div>
      <article
        class="text-primary prose prose-invert text-lime-50 mx-auto lg:prose-lg"
      >
        <Markdown :source="this.content" />
      </article>
      <div class="h-20 sm:h-24"></div>
    </div>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";

export default {
  name: "PrivacyView",
  components: {
    Markdown,
  },
  data() {
    return {
      content: "",
    };
  },

  async created() {
    this.content = await this.privacy_content();
  },
  methods: {
    async privacy_content() {
      let locale = this.$i18n.locale;

      const qs = require("qs");
      let parameters = {
        locale: [locale],
      };
      let parametersString = qs.stringify(parameters);

      const url = new URL(
        process.env.VUE_APP_BACKEND_URL +
          "/api/privacy-policy" +
          "?" +
          parametersString
      );

      let data = {};
      try {
        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`,
          },
        });
        data = await res.json();
      } catch (error) {
        data = {};
      }

      if (data.data) {
        // console.log(data.data.attributes.contenuto);

        let content = data.data.attributes.contenuto;

        content = content.replace(
          "Sm Management & Consulting SAGL",
          "All4Newz News SA"
        );
        content = content.replace(
          "SM MANAGEMENT & CONSULTING SAGL",
          "ALL4NEWZ NEWS SA"
        );
        content = content.replace('"SM"', '"A4N"');
        content = content.replace("smsocialmanagement.com", "all4newz.com");
        content = content.replace(
          "info@smsocialmanagement.com",
          "info@all4newz.com"
        );

        return content;
        // console.log(data.data);
      }
      return "";
    },
  },
};
</script>
