export default {
  "footer": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de Cookies"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de Confidentialité"])},
    "VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Number"])}
  },
  "post": {
    "other-articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire la suite"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])}
  }
}