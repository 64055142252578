<template>
  <div class="post">
    <div
      class="fixed h-screen w-screen top-0 left-0 z-[100] bg-white"
      v-if="loadingToTransition"
    ></div>
    <h1 class="text-4xl md:text-6xl font-extrabold font-display mb-7">
      {{ getPostData.titolo }}
    </h1>
    <div class="lg:w-7/12">
      <div
        class="flex flex-col items-center md:flex-row mb-6 py-4 border-b-2 border-neutral-700"
      >
        <div
          class="bg-yellow-400 p-2 rounded-md font-display md:self-start rounded-full"
        >
          <h3 class="text-2xl leading-none px-3 font-bold text-neutral-900">
            {{ getCurrentTabNumber }}
          </h3>
        </div>
        <div class="pr-2"></div>
        <h3
          class="text-3xl md:text-3xl mt-1 md:mt-0.5 font-display font-bold text-center md:text-left"
        >
          {{ getTabData.titolo_scheda }}
        </h3>
      </div>

      <div class="w-full overflow-hidden mb-6">
        <img class="object-cover w-full" :src="getTabImage" alt="" />
      </div>

      <div
        class="text-primary prose prose-invert text-lime-50 prose-xl md:prose-2xl mb-8 max-w-none prose-p:leading-[1.5]"
      >
        <Markdown :source="getTabData.descrizione" />
      </div>

      <!-- <div class="w-full h-48 bg-gray-50 mb-6"></div> -->
      <div v-if="shouldShowAds" class="mb-6 w-full">
        <a target="_blank" :href="redirectLink" id="adLink">
          <div
            class="bg-neutral-800 p-5 rounded-md"
            style="word-break: break-all"
          >
            <!-- {{ adData }} -->
            <p class="text-lg text-lime-50">
              {{ adData.title }}
            </p>
            <p class="">
              {{ adData.description }}
            </p>
            <p class="text-lime-50">
              {{ adData.siteHost }}
            </p>
          </div>
        </a>
      </div>

      <div class="flex flex-col md:wflex-row gap-2 mb-6">
        <a
          v-if="showPrevious"
          @click="previousPage()"
          class="cursor-pointer grow flex justify-center items-center overflow-hidden p-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <h3 class="text-xl md:text-xl uppercase font-medium">
            {{ $t("post.back") }}
          </h3>
        </a>
        <a
          v-if="showNext"
          @click="checkTrigger()"
          class="cursor-pointer grow flex justify-center items-center bg-yellow-200 hover:bg-yellow-400 transition duration-150 overflow-hidden p-4 rounded-full"
        >
          <h3 class="text-xl md:text-xl uppercase font-bold text-neutral-900">
            {{ $t("post.next") }}
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-neutral-900"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="w-full my-8 md:my-12 border-neutral-700 border-t-2"></div>
    <!-- <div class="w-full mb-6">
      <h1 class="text-3xl md:text-5xl font-bold font-display mb-4">
        {{ $t("post.other-articles") }}
      </h1>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
        <PostCard
          :key="post.id"
          v-for="post in relatedPosts"
          :postData="post"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
// import PostCard from "@/components/PostCard.vue";

import Markdown from "vue3-markdown-it";

export default {
  name: "PostView",
  components: {
    // PostCard,

    Markdown,
  },
  data() {
    return {
      dataReceived: "",
      post: {},
      relatedPosts: [],
      currentTabIndex: 0,
      relatedToShow: 3,
      triggerActivated: false,
      triggersActivated: [],
      shouldActivateTrigger: false,
      loadingToTransition: false,
    };
  },

  async created() {
    if (this.$route.query.page && this.$route.query.page > 1) {
      this.currentTabIndex = this.$route.query.page - 1;
    }

    if (this.$route.params.shouldActivateTrigger === "true") {
      this.shouldActivateTrigger = true;
    }

    window.scrollTo({
      top: 0,
      left: 0,
    });

    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        previousParams;
        this.post = {};
        this.currentTabIndex = 0;
        if (toParams.slug != undefined) {
          this.getPost(toParams.slug);
        }
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }
    );

    this.$watch(
      () => this.$route.query,
      (toQuery, previousQuery) => {
        if (toQuery.page && toQuery.page !== previousQuery.page) {
          this.currentTabIndex = this.$route.query.page - 1;
        } else if (!toQuery.page) {
          this.currentTabIndex = 0;
        }
        // console.log(toQuery, previousQuery);
      }
    );
  },
  mounted() {
    // eslint-disable-next-line
    obApi("track", "PAGE_VIEW");

    if (this.$route.params.slug) {
      this.getPost(this.$route.params.slug);
    }
  },

  computed: {
    redirectLink() {
      let shouldShow =
        this.$route.params.classicMode == "false" &&
        this.$route.params.adData_available == "true";

      if (shouldShow && this.$route.params.adData_ClickUrl) {
        return (
          // "http://127.0.0.1:5173/" +
          "https://www.hopondeals.com/" +
          this.$i18n.locale +
          "/ck?redirect=" +
          this.$route.params.adData_ClickUrl
        );
      } else {
        return window.origin;
      }
    },

    shouldShowAds() {
      return (
        this.$route.params.classicMode == "false" &&
        this.$route.params.adData_available == "true"
      );
    },
    adData() {
      const ret = {
        available: this.$route.params.adData_available,
        title: this.$route.params.adData_title,
        description: this.$route.params.adData_description,
        ClickUrl: this.$route.params.adData_ClickUrl,
        siteHost: this.$route.params.adData_siteHost,
      };
      return ret;
    },

    showPrevious() {
      if (this.currentTabIndex <= 0) {
        return false;
      }
      return true;
    },
    showNext() {
      if (this.isPostLoaded()) {
        if (this.currentTabIndex + 1 >= this.post.attributes.contenuto.length) {
          return false;
        }
        return true;
      }
      return false;
    },
    getPostData() {
      if (this.isPostLoaded()) {
        return this.post.attributes;
      }
      return "";
    },
    getTabData() {
      if (this.isPostLoaded()) {
        if (this.post.attributes.contenuto.length > 0) {
          return this.post.attributes.contenuto[this.currentTabIndex];
        }
      }
      return [];
    },
    getTabImage() {
      if (this.isPostLoaded()) {
        if (this.post.attributes.contenuto.length > 0) {
          if (
            this.post.attributes.contenuto[this.currentTabIndex].immagine_scheda
          ) {
            const isWGM =
              this.$route.params.isWGM && this.$route.params.isWGM == "true";
            const backendURL = isWGM
              ? process.env.VUE_APP_BACKEND_URL_QF
              : process.env.VUE_APP_BACKEND_URL;
            return (
              backendURL +
              this.post.attributes.contenuto[this.currentTabIndex]
                .immagine_scheda.data.attributes.url
            );
          } else {
            return require("@/assets/placeholder_post_image.svg");
          }
        }
      }
      return "";
    },
    getCurrentTabNumber() {
      if (this.isPostLoaded()) {
        return this.currentTabIndex + 1;
      }
      return "-";
    },
  },
  methods: {
    isPostLoaded() {
      if (typeof this.post.attributes !== "undefined") {
        return true;
      }
      return false;
    },

    openTriggerPage() {
      // eslint-disable-next-line
      //obApi("track", "Lead Yahoo");

      if (this.$route.params.classicMode == "false") {
        // eslint-disable-next-line

        // @ts-ignore
        //eslint-disable-next-line
        _tfa.push({ notify: "event", name: "clickoutall", id: 1590298 });

        document.getElementById("adLink").click();

        this.nextPage();
      } else {
        const isWGM =
          this.$route.params.isWGM == "true" ||
          this.$route.params.isWGM == true;

        if (isWGM) {
          // eslint-disable-next-line
          _tfa.push({ notify: "event", name: "Clickoutall", id: 1590281 });
          // eslint-disable-next-line
          _tfa.push({ notify: "event", name: "clickoutok", id: 1590291 });
          // eslint-disable-next-line
          _tfa.push({ notify: "event", name: "clickoutna", id: 1607705 });
          // eslint-disable-next-line
          _tfa.push({ notify: "event", name: "clickoutna3", id: 1607746 });
        } else {
          // eslint-disable-next-line
          _tfa.push({ notify: "event", name: "clickoutyh", id: 1590300 });
          // eslint-disable-next-line
          _tfa.push({ notify: "event", name: "clickoutna2", id: 1607756 });
          // eslint-disable-next-line
          _tfa.push({ notify: "event", name: "clickoutna3", id: 1607746 });
        }

        // eslint-disable-next-line
        _tfa.push({ notify: "event", name: "clickoutsm", id: 1607708 });
        // eslint-disable-next-line
        _tfa.push({ notify: "event", name: "clickoutsm2", id: 1607703 });
        // eslint-disable-next-line
        _tfa.push({ notify: "event", name: "clickoutsm3", id: 1607747 });
        // eslint-disable-next-line
        _tfa.push({ notify: "event", name: "Clickoutmob", id: 1607758 });

        // eslint-disable-next-line
        _tfa.push({ notify: "event", name: "leadpxqf", id: 1704662 });

        // eslint-disable-next-line
        _tfa.push({ notify: "event", name: "leadpxhop", id: 1704663 });

        const wgmFixedArid = this.$route.params.wgmFixedArid;
        let url;

        if (isWGM) {
          url = new URL(
            "https://www.qfindnow.com/" +
              // "http://localhost:8081/" +
              // "http://127.0.0.1:5173/" +
              (this.$i18n.locale + "/4722175671/" + wgmFixedArid)
          );
          url.searchParams.set("slug", this.$route.params.slug);
          url.searchParams.set("site_id", this.$route.params.utm_source);
          url.searchParams.set("forceLocale", true);
          url.searchParams.set("page", this.currentTabIndex + 1 + 1);
          url.searchParams.set("b5m", true);
          url.searchParams.set("forceRef", true);
          url.searchParams.set("utm_campaign", this.$route.params.utm_campaign);
          url.searchParams.set("altCond", this.$route.params.altCond);
          // if (
          //   this.$route.params.tbclid &&
          //   ["6766", "6767", "6768", "6769", "7165"].includes(
          //     this.$route.params.utm_source
          //   )
          // ) {
          //   url.searchParams.set("tbclid", this.$route.query.tbclid);
          // }
        } else {
          url = new URL(
            "https://www.hopondeals.com/" +
              // "http://127.0.0.1:5173/" +
              (this.$i18n.locale + "/7105320235")
          );
          url.searchParams.set("slug", this.$route.params.slug);
          url.searchParams.set("utm_source", this.$route.params.utm_source);
          url.searchParams.set("utm_campaign", this.$route.params.utm_campaign);
          // url.searchParams.set("forceLocale", true);
          url.searchParams.set("page", this.currentTabIndex + 1);
          url.searchParams.set("forceRef", true);
          url.searchParams.set("b5m", true);
          url.searchParams.set("altCond", this.$route.params.altCond);
          // if (
          //   this.$route.params.tbclid &&
          //   ["8373"].includes(this.$route.params.utm_source)
          // ) {
          //   url.searchParams.set("tbclid", this.$route.query.tbclid);
          // }
          // url.searchParams.set("utm_campname", "outbrain");
        }

        console.log(this.$route.params.isNewTaboola);

        if (this.$route.params.isNewTaboola == "true") {
          url.searchParams.set("ntb", true);
        }

        const sleep = (ms) => {
          return new Promise((resolve) => setTimeout(resolve, ms));
        };

        this.loadingToTransition = true;

        sleep(500).then(() => {
          window.location.replace(url);
        });
      }
    },

    checkTrigger() {
      // let isTriggerPage =
      //   this.$route.params.triggerPage == this.currentTabIndex + 1;
      let triggerPages = this.$route.params.triggerPages || [];

      let isTriggerPage = triggerPages.includes(
        (this.currentTabIndex + 1).toString()
      );

      let isTriggerActivated = this.triggersActivated.includes(
        (this.currentTabIndex + 1).toString()
      );

      if (this.shouldActivateTrigger && !isTriggerActivated && isTriggerPage) {
        this.triggersActivated.push((this.currentTabIndex + 1).toString());
        this.openTriggerPage();
      } else {
        this.nextPage();
      }
    },
    previousPage() {
      this.currentTabIndex -= 1;
      window.scrollTo({
        top: 0,
        left: 0,
      });
      this.updatePage();
    },
    nextPage() {
      if (this.$route.params.classicMode == "false") {
        // console.log(this.currentTabIndex);
        this.$router.push({
          name: "Redirect",
          params: { locale: this.$i18n.locale },
          query: {
            cl: this.$route.params.classicMode,
            slug: this.$route.params.slug,
            utm_source: this.$route.params.utm_source,
            utm_campaign: this.$route.params.utm_campaign,
            altCond: this.$route.params.altCond,
            page: this.currentTabIndex + 1 + 1,
          },
        });
      } else {
        this.currentTabIndex += 1;
        window.scrollTo({
          top: 0,
          left: 0,
        });
        this.updatePage();
      }
    },
    updatePage() {
      this.$router.push({
        query: { ...this.$route.query, page: this.currentTabIndex + 1 },
      });
    },

    checkIfPageNumberExists() {
      if (
        this.currentTabIndex > this.post.attributes.contenuto.length ||
        this.currentTabIndex < 0
      ) {
        // console.log(this.$route.query);
        this.currentTabIndex = 0;
        let newQuery = { ...this.$route.query };
        delete newQuery.page;
        this.$router.push({ query: newQuery });
      }
    },

    async getPost(slug) {
      let locale = this.$i18n.locale;

      const qs = require("qs");
      let parameters = {
        filters: {
          codice_paese: { $eq: locale },
          slug: {
            $eq: slug,
          },
        },
        populate: ["cover", "contenuto", "contenuto.immagine_scheda"],
      };
      let parametersString = qs.stringify(parameters);
      const isWGM =
        this.$route.params.isWGM && this.$route.params.isWGM == "true";
      const backendURL = isWGM
        ? process.env.VUE_APP_BACKEND_URL_QF
        : process.env.VUE_APP_BACKEND_URL;
      const bearerToken = isWGM
        ? process.env.VUE_APP_BEARER_TOKEN_QF
        : process.env.VUE_APP_BEARER_TOKEN;

      console.log();

      const url = new URL(backendURL + "/api/posts" + "?" + parametersString);
      // url.searchParams.append("populate", "*");

      let data = {};
      try {
        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        data = await res.json();
      } catch (error) {
        data = {};
      }

      if (data.data && Array.isArray(data.data) && data.data.length > 0) {
        this.dataReceived = data;
        this.post = data.data[0];
        this.checkIfPageNumberExists();
        // console.log(data.data);
      } else {
        this.$router.push({
          name: "Home",
          params: { locale: this.$i18n.locale },
        });
      }

      let relatedParameters = {
        filters: {
          codice_paese: { $eq: locale },
        },
        populate: ["cover"],
        sort: ["publishedAt:desc"],
        pagination: {
          pageSize: this.relatedToShow + 1,
        },
      };
      let relatedParametersString = qs.stringify(relatedParameters);

      const relatedUrl = new URL(
        backendURL + "/api/posts?" + relatedParametersString
      );

      data = {};
      try {
        const res = await fetch(relatedUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        data = await res.json();
      } catch (error) {
        data = {};
      }

      if (Array.isArray(data.data) && data.data.length) {
        this.dataReceived = data;
        this.relatedPosts = data.data;
        let indexToRemove = this.relatedToShow;
        for (let post of this.relatedPosts) {
          if (this.post.attributes.slug == post.attributes.slug) {
            indexToRemove = this.relatedPosts.indexOf(post);
          }
        }
        this.relatedPosts.splice(indexToRemove, 1);
      }
    },
  },
};
</script>
