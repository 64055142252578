<template>
  <div class="w-full mb-6">
    <div class="flex flex-col md:flex-row gap-2 md:gap-4">
      <PostCard
        :key="post.id"
        v-for="post in highlightedPosts"
        :postData="post"
        :big="true"
      />
    </div>
  </div>

  <div class="w-full mb-6">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
      <PostCard :key="post.id" v-for="post in otherPosts" :postData="post" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloI18n from "@/components/HelloI18n.vue";
import PostCard from "@/components/PostCard.vue";

export default {
  name: "HomeView",
  components: {
    PostCard,
    // HelloI18n,
  },
  data() {
    return {
      posts: [],
      highlightedPostsNumber: 1,
      dataReceived: "",
    };
  },
  async mounted() {
    let locale = this.$i18n.locale;

    const qs = require("qs");
    let parameters = {
      filters: {
        codice_paese: { $eq: locale },
      },
      populate: ["cover"],
      sort: ["publishedAt:desc"],
    };
    let parametersString = qs.stringify(parameters);

    const url = new URL(
      process.env.VUE_APP_BACKEND_URL + "/api/posts?" + parametersString
    );

    let data = {};
    try {
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`,
        },
      });
      data = await res.json();
    } catch (error) {
      data = {};
    }

    if (Array.isArray(data.data) && data.data.length) {
      this.dataReceived = data;
      this.posts = data.data;
    }
  },
  computed: {
    highlightedPosts() {
      return this.posts.slice(0, this.highlightedPostsNumber);
    },
    otherPosts() {
      return this.posts.slice(this.highlightedPostsNumber);
    },
  },
};
</script>
